import { PROFILE_BASE_URL } from "../common/constants/commonUrl";
export const isWindowAvailable = typeof window !== "undefined";
import { IncomingMessage } from "http";
import { parse } from "cookie";
import { AuthData } from "../types/common.types";
import axios from "axios";
import { Config } from "./config";
import { paidDMMsgType } from "./common.types";
import { rawType } from "@/context/AppContext";

export const KMFormatterTen = (num: number) => {
  let temp: number | string = Math.abs(num) || 0;
  if (Math.abs(num) >= 1000000) {
    temp = `${Math.sign(num) * Number((Math.abs(num) / 1000000).toFixed(1))}M`; // convert to M for number from > 1 million
  } else if (Math.abs(num) >= 10000) {
    temp = `${Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1))}K`; // convert to K for number from > 10000 < 1 million
  }
  return temp;
};
export const getMainDomain = (hostname: string) => {
  return hostname?.split(".").slice(-2).join(".");
};

export const ProfileImageUrl = (url: string) => {
  return `${PROFILE_BASE_URL}${url}`;
};

export const captialize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function (...args: Parameters<T>): void {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function getCurrentPosition(): Promise<GeolocationPosition> {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (isWindowAvailable) {
      navigator?.geolocation?.getCurrentPosition(resolve, reject);
    } else {
      reject("Error Fetching Geolocation....");
    }
  });
}

export const covertUrlToHttps = (url: string) => {
  return url?.replace(/^http:\/\//i, "https://");
};

export const parseRouteWithoutQuery = (str: string) =>
  str.slice(0, str.indexOf("?"));

export const getAuthDataFromCookie = (req: IncomingMessage) => {
  try {
    const parsedCookie = parse(req?.headers?.cookie as string);
    const authCookie = parsedCookie?.SSR_AUTH;

    const buffer = Buffer.from(authCookie, "base64");
    const jsonString = buffer.toString("utf8");
    const jsonObject = JSON.parse(jsonString) as AuthData;
    jsonObject.user.token = jsonObject.idToken;
    jsonObject.user.isAuthenticated = jsonObject.isAuthenticated;
    return jsonObject?.user;
  } catch (error) {
    console.log(error);
  }
};

export function getUsernameColor(username: string): string {
  let hashCode = 0;
  for (let i = 0; i < username?.length; i++) {
    hashCode = username.charCodeAt(i) + ((hashCode << 5) - hashCode);
  }

  let colorCode = (hashCode & 0x00ffffff).toString(16);
  colorCode = "00000".substring(0, 6 - colorCode.length) + colorCode;

  return "#" + colorCode;
}

export const deviceType = () => {
  if (isWindowAvailable) {
    if (global?.navigator?.userAgent?.match(/iPhone/i)) {
      return "iPhone";
    } else if (global?.navigator?.userAgent?.match(/Android/i)) {
      return "android";
    }
  }
  return "desktop";
};

/**
 * @param {'image'|'video'} filetype image or video to check
 * @param {string} filename image or video filename to check
 **/
export const validateFileFormat = (
  filetype: "image" | "video",
  filename: string
) => {
  switch (filetype) {
    case "image":
      return /\.(jpe?g|png|heic|pdf)$/i.test(filename);
    case "video":
      return /\.(mp4|mpeg|mov|m4a|3gp|m3u8)$/i.test(filename);
    default:
      break;
  }
};

export const checkPanFormat = (panNumber = "") => {
  const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

  return regpan.test(panNumber);
};

export const formatSeconds = (sec = 0) => {
  if (sec > 900) {
    sec = 900;
  } else if (sec < 0) {
    sec = 0;
  }
  const hrs = Math.floor(sec / 3600);
  const min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;
  seconds = Math.round(seconds * 100) / 100;

  let result = hrs ? (hrs < 10 ? "0" + hrs + ":" : hrs + ":") : "";
  result += min < 10 ? "0" + min : min;
  result += ":" + (seconds < 10 ? "0" + seconds : seconds);
  return result;
};

export const toFixedDigits = (num: number, dec = 4) => {
  const calcDec = Math.pow(10, dec);
  return Math.trunc(num * calcDec) / calcDec;
};

export const getPlatform = () => {
  let userAgent = "";
  if (isWindowAvailable) {
    userAgent = window.navigator.userAgent.toLowerCase();
  }
  const ios = /iphone|ipod|ipad/.test(userAgent);
  let platform;
  if (ios) {
    platform = "ios";
  } else {
    platform = "android";
  }
  return platform;
};
export const sendLogs = (msg: string) => {
  void axios.post(`${Config.Backend_Url}/logger`, {
    message: msg,
  });
};

export const formatName = (name: string) => {
  return name;
  const maxLength = 30;
  let trimmedName = name;
  // if name contains  3 words trim last word
  if (name.split(" ").length > 2) {
    trimmedName = name.split(" ").slice(0, 2).join(" ");
  }
  // Subtract 3 for the ellipsis itself
  trimmedName = name.substring(0, maxLength - 3);
  return trimmedName + (trimmedName.length > maxLength ? "..." : "");
};

export function isAdult(dateOfBirth: Date, today = new Date()) {
  const ageInMilliseconds = today.getTime() - dateOfBirth.getTime();
  const ageInYears = Math.floor(
    ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)
  );
  return ageInYears >= 18;
}

type ThrottleFunction<T extends any[]> = (...args: T) => void;

export function throttle<T extends any[]>(
  func: ThrottleFunction<T>,
  delay: number
): ThrottleFunction<T> {
  let timer: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export function convertSecondsToTime(seconds: number) {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
}

export const getPastDate = (date: Date, n: number) => {
  date.setDate(date.getDate() - n);
  return date;
};

export const generateRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getRandomNumberBasedOnUsername = (username: string) => {
  // Calculate the sum of the ASCII codes of each character in the username
  let sum = 0;
  for (let i = 0; i < username.length; i++) {
    sum += username.charCodeAt(i);
  }

  // Use the sum to generate a number between 5 and 10
  const randomNumber = (sum % 6) + 5;

  return randomNumber;
};

export const formatDate = (date: Date, seperator = "") => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join(seperator);
};

export const convertTo12HourFormat = (isoString: string): string => {
  // Create a Date object from the ISO string
  const date = new Date(isoString);

  // Extract hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // If hour is 0, set it to 12

  // Pad minutes to always be two digits
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  // Format the result as `HH:MM AM/PM`
  return `${hours}:${minutesString} ${ampm}`;
};

export const convertMinutesToHours = (
  totalMinutes: number
): { hours: number; minutes: number } => {
  const hours = Math.floor(totalMinutes / 60); // Calculate hours
  const minutes = totalMinutes % 60; // Calculate remaining minutes
  return { hours, minutes }; // Return the result as an object
};

export const getMessageFormat = (data: {
  value?: string;
  roomId: string;
  file?: string;
  type?: paidDMMsgType;
  raw?: rawType;
}) => {
  return {
    type: data.type ?? paidDMMsgType.DEFAULT,
    data: {
      body: data?.value,
      urls: [data?.file ? data?.file : ""],
      raw: data.raw,
    },
    roomId: data.roomId,
  };
};

export const capitalizeWords = (str: string) => {
  if (!str || typeof str !== "string") return str;
  return str
    .replaceAll(/_/g, " ")
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const removeUnderscore = (str: string) => {
  if (!str || typeof str !== "string") return str;
  return str.replaceAll(/_/g, " ");
};
